import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import {
  Autocomplete,
  BillingTypes,
  CreatableAutocomplete,
  DatePicker,
  LoadingButton,
  NumberTextField,
} from 'components';
import { sortByField, submitForm } from 'utils';
import { useCreatableProjectRoleData, useCreatableSeniorityData, usePermissions } from 'hooks';
import { ActionsType, Assignment, AssignmentCalculationType, MemberSeniority, Role } from 'generated/types';
import { ASC, NAME } from 'consts';
import InputLabel from '@material-ui/core/InputLabel';
import { FormikHelpers, useFormik } from 'formik';
import { DashIcon } from 'icons';
import * as Yup from 'yup';

import styles from './styles.module.scss';

type BillingTypeOption = { id: AssignmentCalculationType; name: string };
type BillableOption = { id: boolean; name: string };
export interface FormValues {
  role: Pick<Role, 'id' | 'name'> | null;
  seniority: Pick<MemberSeniority, 'id' | 'name'> | null;
  allocationTimeAmount: number | string;
  billingType: BillingTypeOption | null;
  startDate: string | Date;
  endDate: string | Date;
  billable: BillableOption | null;
}

interface Props {
  isLoading?: boolean;
  disabledBillable?: boolean;
  onSubmit: (value: FormValues) => void;
  onCancel: () => void;
  hideBillable?: boolean;
}

export const EditAssignmentsForm: FC<Props> = ({ onCancel, onSubmit, disabledBillable, hideBillable, isLoading }) => {
  const { t } = useTranslation();
  const { hasAccess } = usePermissions();

  const { seniorities, getCreatedSeniority } = useCreatableSeniorityData();
  const { roles, getCreatedProjectRole } = useCreatableProjectRoleData();

  const sortedRoles = useMemo(() => sortByField(roles, ASC, NAME), [roles]);
  const sortedSeniorities = useMemo(() => sortByField(seniorities, ASC, NAME), [seniorities]);
  const getBillingTypeOptions = ({ name }: BillingTypeOption) => t(name);

  const handleSubmitForm = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const role = await getCreatedProjectRole(values.role);
    const seniority = await getCreatedSeniority(values.seniority);
    await submitForm({ ...values, role, seniority }, setSubmitting, onSubmit);
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        role: Yup.object().nullable(),
        seniority: Yup.object().nullable(),
        allocationTimeAmount: Yup.number()
          .typeError(t('forms.newAssignment.allocationDecimal'))
          .nullable()
          .test(
            'maxDigitsAfterDecimal',
            t('forms.newAssignment.allocationDecimal'),
            (number) => !number || /^\d+(\.\d{1,2})?$/.test(`${number}`),
          )
          .positive(t('forms.newAssignment.allocationPositiveError')),
        startDate: Yup.string().nullable(),
        endDate: Yup.string().nullable(),
        billingType: Yup.object().nullable(),
        billable: Yup.object().nullable(),
      }),
    [],
  );

  const { handleBlur, handleSubmit, values, setFieldValue, errors, touched, submitCount } = useFormik<FormValues>({
    onSubmit: handleSubmitForm,
    validationSchema,
    initialValues: {
      role: null,
      seniority: null,
      startDate: '',
      endDate: '',
      billingType: null,
      allocationTimeAmount: '',
      billable: null,
    },
  });

  const isChanged =
    !!values.role ||
    !!values.seniority ||
    !!values.startDate ||
    !!values.endDate ||
    !!values.billingType ||
    !!values.allocationTimeAmount ||
    !!values.billable;

  return (
    <form className="form">
      <div>
        <div className={styles.twoItemsBox}>
          <div className="flex-1">
            <InputLabel>{t('forms.newAssignment.projectRole')}</InputLabel>
            <CreatableAutocomplete
              placeholder={t('forms.newAssignment.projectRole')}
              name="role"
              onBlur={handleBlur}
              value={values.role}
              error={touched.role ? errors.role : undefined}
              onChange={(role: FormValues['role']) => setFieldValue('role', role)}
              options={sortedRoles || []}
              enableCreation={hasAccess(ActionsType.OtherSettings)}
            />
          </div>
          <div className="flex-1">
            <InputLabel>{t('forms.newAssignment.seniority')}</InputLabel>
            <CreatableAutocomplete
              placeholder={t('forms.newAssignment.seniority')}
              name="seniority"
              onBlur={handleBlur}
              value={values.seniority}
              error={touched.seniority ? errors.seniority : undefined}
              onChange={(seniority: FormValues['seniority']) => setFieldValue('seniority', seniority)}
              options={sortedSeniorities || []}
              enableCreation={hasAccess(ActionsType.OtherSettings)}
            />
          </div>
        </div>

        <div className="flex mb-16">
          <div className="flex-1">
            <InputLabel>{t('forms.newAssignment.dateFrom')}</InputLabel>
            <DatePicker
              placeholder="DD/MM/YYYY"
              value={values.startDate}
              error={Boolean(submitCount && errors.startDate)}
              helperText={!!submitCount && errors.startDate}
              onChange={(startDate: Assignment['startDate']) => setFieldValue('startDate', startDate)}
            />
          </div>
          <div className={styles.datePickersDash}>
            <DashIcon />
          </div>
          <div className="flex-1">
            <InputLabel>{t('forms.newAssignment.dateTo')}</InputLabel>
            <DatePicker
              placeholder="DD/MM/YYYY"
              value={values.endDate}
              error={Boolean(submitCount && errors.endDate)}
              helperText={!!submitCount && errors.endDate}
              onChange={(endDate: Assignment['endDate']) => setFieldValue('endDate', endDate)}
            />
          </div>
        </div>

        <div className={styles.twoItemsBox}>
          <div className="w-100 mb-8">
            <InputLabel>{t('forms.newAssignment.billingType.label')}</InputLabel>
            <Autocomplete
              options={BillingTypes}
              getOptionLabel={getBillingTypeOptions}
              name="billingType"
              onBlur={handleBlur}
              value={values.billingType}
              error={touched.billingType ? errors.billingType : undefined}
              placeholder={t('forms.select')}
              onChange={(billingType: FormValues['billingType']) => setFieldValue('billingType', billingType)}
            />
          </div>
          <div className="w-100">
            <InputLabel>{t('forms.newAssignment.allocation')}</InputLabel>
            <NumberTextField
              placeholder="0"
              name="allocationTimeAmount"
              onChange={(e) => setFieldValue('allocationTimeAmount', e.target.value)}
              onBlur={handleBlur}
              value={values.allocationTimeAmount}
            />
          </div>
        </div>

        {!hideBillable && (
          <div className={styles.twoItemsBox}>
            <div className={styles.billable}>
              <InputLabel>{t('forms.newAssignment.billable')}</InputLabel>
              <Autocomplete
                options={[
                  { id: true, name: t(`common.condition.true`) },
                  { id: false, name: t(`common.condition.false`) },
                ]}
                name="billable"
                onBlur={handleBlur}
                value={values.billable}
                error={touched.billable ? errors.billable : undefined}
                placeholder={t('forms.select')}
                onChange={(billable: FormValues['billable']) => setFieldValue('billable', billable)}
                disabled={disabledBillable}
              />
            </div>
          </div>
        )}
      </div>

      <div className="controls">
        <div className="flex gap-8">
          <LoadingButton disabled={!isChanged} onClick={() => handleSubmit()} loading={isLoading}>
            {t('forms.save')}
          </LoadingButton>
          <Button variant="outlined" color="secondary" onClick={onCancel}>
            {t('forms.cancel')}
          </Button>
        </div>
      </div>
    </form>
  );
};
