import { RequestStatusType } from 'generated/types';
import { RequestDataFragmentDoc, useDeleteRequestsMutation, useEditRequestsStatusMutation } from '../generated/graphql';
import { graphqlOnError } from '../utils';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import { Reference } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { useCountPendingRequest } from './useCountPendingRequest';

export const useRequestActions = () => {
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const { decreaseCountPendingRequest } = useCountPendingRequest();

  const [editRequestsStatus] = useEditRequestsStatusMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted(data) {
      toast.success(
        data.editRequestsStatus.length === 1
          ? t(`activity.requestsInbox.notifications.${data.editRequestsStatus[0].status}`)
          : t(`activity.requestsInbox.notifications.${data.editRequestsStatus[0].status}Multiple`),
      );
      decreaseCountPendingRequest(data.editRequestsStatus.length);

      data.editRequestsStatus.forEach((data) => {
        mixpanel.track(data.status === RequestStatusType.Approved ? 'Request approved' : 'Request declined');
      });
    },
    update(cache, { data }) {
      if (!data?.editRequestsStatus.length) return;

      data?.editRequestsStatus.forEach((request) => {
        const updatedRequestRef = cache.writeFragment({
          data: request,
          fragment: RequestDataFragmentDoc,
        });

        cache.modify({
          fields: {
            requests(requests: Reference[] = []) {
              return requests.map((requestRef) =>
                requestRef.__ref === updatedRequestRef?.__ref ? request : requestRef,
              );
            },
          },
        });
      });
    },
  });

  const [deleteRequestsDocument] = useDeleteRequestsMutation({
    onError(err) {
      graphqlOnError(err, tls(err.message));
    },
    onCompleted({ deleteRequests }) {
      toast.success(
        t(
          deleteRequests.length === 1
            ? 'activity.requestsInbox.notifications.deleted'
            : 'activity.requestsInbox.notifications.deletedMultiple',
        ),
      );
    },
    update(cache, { data }) {
      if (!data?.deleteRequests.length) return;

      data?.deleteRequests.forEach((request) => cache.evict({ id: cache.identify(request) }));
      cache.gc();
    },
  });

  return { editRequestsStatus, deleteRequestsDocument };
};
