export enum LanguageEnum {
  EN = 'en',
}

export enum DefaultTableOptionEnum {
  pageSize = 10,
  pageIndex = 0,
}

export enum ModalModeEnum {
  create = 'create',
  edit = 'edit',
  multipleEdit = 'multiple-edit',
  requestCreate = 'request-create',
  requestEdit = 'request-edit',
  requestCreateEdit = 'request-create-edit',
  manage = 'manage',
  request = 'request',
  leave = 'leave',
  assigmentIntegration = 'assigment-integration',
}
