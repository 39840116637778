import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, TextField } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormik } from 'formik';

import { Autocomplete, DialogWrapper, LoadingButton, Portal, Tooltip, UploadFileItem } from 'components';
import { useErrorMsgBuilder, useFeedback, useIsOpen } from 'hooks';
import { PaperClip, QuestionIcon } from 'icons';
import * as Yup from 'yup';
import {
  generateRandomId,
  getFileNameWithoutSpecificSymbols,
  getUniqueFileName,
  graphqlOnError,
  MEGABYTE,
} from 'utils';
import { toast } from 'react-toastify';
import { client } from 'graphql-client';
import { UploadFeedbackFileDocument } from 'generated/graphql';

import styles from './styles.module.scss';
import clsx from 'clsx';

export type FormValues = {
  issueType?: { id: string; name: string };
  description?: string;
};

interface File {
  size: number;
  name: string;
  id: string;
  abortController: AbortController;
}
interface LoadedFile {
  size: number;
  name: string;
  id: string;
  fileUrl: string;
}

const MaxFileLimit100Mb = 100 * MEGABYTE;
const CancelRequestErrorMessage = 'text is not a function';
const AbortRequestErrorMessage = 'The user aborted a request.';
const VideoType = 'video';
const ImageType = 'image';

export const FeedbackModal: FC = () => {
  const { t } = useTranslation();
  const [isOpen, onOpen, onClose] = useIsOpen();
  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const [loadingFiles, setLoadingFiles] = useState<File[]>([]);
  const [files, setFiles] = useState<LoadedFile[]>([]);
  const tls = useErrorMsgBuilder();
  const { sendFeedback } = useFeedback();

  const issueTypes = useMemo(
    () => [
      { id: t('feedback.form.issueCategories.bugReport'), name: t('feedback.form.issueCategories.bugReport') },
      {
        id: t('feedback.form.issueCategories.enhancementSuggestion'),
        name: t('feedback.form.issueCategories.enhancementSuggestion'),
      },
      {
        id: t('feedback.form.issueCategories.productQuestion'),
        name: t('feedback.form.issueCategories.productQuestion'),
      },
      { id: t('feedback.form.issueCategories.other'), name: t('feedback.form.issueCategories.other') },
    ],
    [],
  );

  const onSubmit = (data: FormValues) => {
    const filesUrls = files.map(({ fileUrl }) => fileUrl);
    sendFeedback({ data, files: filesUrls, onCompleted: onClose });
  };

  const handleClose = () => {
    onClose();
    loadingFiles.forEach(({ abortController }) => abortController.abort());
  };

  const handleDelete = (id: string) => {
    setFiles((prev) => prev.filter((item) => item.id !== id));
  };

  const resetInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onChange = async ({ target: { validity, files } }: ChangeEvent<HTMLInputElement>) => {
    if (!validity.valid || !files?.length) {
      return;
    }

    [...files].forEach((file) => {
      const abortController = new AbortController();

      if (file.size > MaxFileLimit100Mb) {
        toast.error(t('projectFile.notifications.maxFileSize'));
        return;
      }

      if (!(file.type.includes(VideoType) || file.type.includes(ImageType))) {
        toast.error(t('projectFile.notifications.onlyMediaFile'));
        return;
      }

      const sameFilesCount = loadingFiles.filter(({ name }) => name.includes(file.name.split('.')[0])).length;
      const newFile = sameFilesCount
        ? new File([file], getUniqueFileName(getFileNameWithoutSpecificSymbols(file.name), sameFilesCount), {
            type: file.type,
          })
        : new File([file], getFileNameWithoutSpecificSymbols(file.name), {
            type: file.type,
          });

      client
        .mutate({
          mutation: UploadFeedbackFileDocument,
          fetchPolicy: 'network-only',
          variables: { file: newFile },
          context: {
            fetchOptions: { signal: abortController.signal },
          },
        })
        .then(({ data }) => {
          if (data?.uploadFeedbackFile) {
            const { uploadFeedbackFile: fileUrl } = data;

            setFiles((prev) => [...prev, { id: generateRandomId(), name: newFile.name, size: newFile.size, fileUrl }]);
            setLoadingFiles((prev) => prev.filter(({ name, size }) => name !== newFile.name || size !== newFile.size));
            toast.success(t('projectFile.notifications.create'));
          }
        })
        .catch((err) => {
          if (err.message.includes(CancelRequestErrorMessage) || err.message === AbortRequestErrorMessage) {
            return;
          }
          graphqlOnError(err, tls(err.message));
        });

      setLoadingFiles((prev) => [
        ...prev,
        { id: generateRandomId(), name: newFile.name, size: newFile.size, abortController: abortController },
      ]);
    });

    resetInput();
  };

  const {
    handleChange,
    handleSubmit,
    setValues,
    handleBlur,
    isSubmitting,
    values,
    touched,
    errors,
    resetForm,
  } = useFormik<FormValues>({
    initialValues: {
      issueType: issueTypes[0],
      description: '',
    },
    validationSchema: Yup.object({
      issueType: Yup.object().required(t('validation.required')),
      description: Yup.string()
        .required(t('validation.required'))
        .matches(/^\s*\S[\s\S]*$/, t('validation.blankspaces')),
    }),
    onSubmit,
  });

  useEffect(() => {
    if (isOpen) {
      resetForm();
      setFiles([]);
      setLoadingFiles([]);
    }
  }, [isOpen]);

  return (
    <>
      <IconButton onClick={onOpen} className={styles.openButton}>
        <Tooltip title={t('feedback.tooltipText')!} className={styles.tooltip} placement="top" alwaysShowTooltip>
          <QuestionIcon />
        </Tooltip>
      </IconButton>

      <DialogWrapper
        open={isOpen}
        onClose={handleClose}
        className={styles.dialog}
        contentClassName={styles.dialogContent}
        title={t('feedback.contactUs')}
      >
        <form onSubmit={handleSubmit}>
          <div className={styles.box}>
            <span className={styles.description}>{t('feedback.text')}</span>
            <InputLabel>{t('feedback.form.issueType')}</InputLabel>
            <Autocomplete
              name="issueType"
              placeholder={'Product feedback'}
              onBlur={handleBlur}
              value={values.issueType}
              className="mb-24"
              disableClearable
              options={issueTypes}
              error={touched.issueType ? errors.issueType : undefined}
              onChange={(issueType: FormValues['issueType']) => setValues({ ...values, issueType })}
            />

            <InputLabel>{t('feedback.form.description')}</InputLabel>
            <TextField
              name="description"
              className={styles.noteTextarea}
              id="note-text-field"
              placeholder={t('feedback.form.descriptionPlaceholder')}
              multiline
              rows={10}
              onChange={handleChange}
              error={touched.description ? !!errors.description : undefined}
              helperText={touched.description ? errors.description : undefined}
              value={values.description}
            />

            <div className={styles.dropZone}>
              <input
                type="file"
                multiple
                onChange={onChange}
                ref={fileInputRef}
                id="contained-file"
                size={MaxFileLimit100Mb}
                className={styles.fileInput}
              />
              <Button startIcon={<PaperClip />} variant="outlined" color="secondary" className="mt-24">
                {t('feedback.form.attachFiles')}
              </Button>
            </div>

            <div className={clsx(styles.fileList, !files.length && !loadingFiles.length && 'm-0')}>
              {files.map(({ size, name, id }) => (
                <UploadFileItem
                  key={id}
                  name={name}
                  size={size}
                  handleDelete={() => {
                    handleDelete(id);
                  }}
                />
              ))}
              {loadingFiles.map(({ id, size, name, abortController }) => (
                <UploadFileItem
                  loading
                  key={id}
                  name={name}
                  size={size}
                  handleDelete={() => {
                    abortController.abort();
                    setLoadingFiles((prev) => prev.filter((item) => item.id !== id));
                  }}
                />
              ))}
            </div>
          </div>

          <Portal wrapperId="dialog-actions">
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              {t('feedback.form.cancel')}
            </Button>

            <LoadingButton type="submit" loading={isSubmitting} onClick={() => handleSubmit()}>
              {t('feedback.form.submit')}
            </LoadingButton>
          </Portal>
        </form>
      </DialogWrapper>
    </>
  );
};
