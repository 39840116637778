import { useAuth } from 'contexts';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useErrorMsgBuilder } from './useErrorMsgBuilder';
import { FormValues } from '../components/FeedbackModal/FeedbackModal';
import { graphqlOnError } from 'utils';
import { toast } from 'react-toastify';

interface SendFeedbackProps {
  data: FormValues;
  files: string[];
  onCompleted?: () => void;
}
export const useFeedback = () => {
  const { userData } = useAuth();
  const { t } = useTranslation();
  const tls = useErrorMsgBuilder();
  const sendFeedback = ({ data, files, onCompleted }: SendFeedbackProps) => {
    const feedback = {
      submittedBy: userData?.member.email,
      issueType: data.issueType?.name,
      description: data.description?.trim(),
      submittedAt: format(new Date(), 'LLL d, yyyy, h:mm a'),
      files,
      env: t(`feedback.environments.${process.env.REACT_APP_ENV_NAME}`),
    };

    fetch(process.env.REACT_APP_ZAPIER_URL!, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(feedback),
    })
      .then(() => {
        toast.success(t('feedback.form.createSuccessfully'));
        onCompleted?.();
      })
      .catch((err) => graphqlOnError(err, tls(err.message)));
  };

  return { sendFeedback };
};
